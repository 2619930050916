import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import TitleCard from './TitleCard';
import ScrollMenu from './ScrollMenu';
import PagerBlock from './PagerBlock';
import categoryCode2Name from '../../../../common/categoryCode2Name';
import * as ERROR from '../../../../constants/error';
import removeTag from '../../../../common/react-removeTag';
import routes from '../../../common/routes';
import { getCardResizeImage } from '../../../../common/preloadImages';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import TaboolaWidget from '../../../common/components/TaboolaWidget';
import GetPageSchemaObjects from '../../../common/utils/getPageSchemaObjects';
import classnames from 'classnames';
import { validatePageIndexWithinRange } from '../../../common/utils/validateQueryParameters';
import getNofollowThresholdValue from '../../../common/utils/getNofollowThresholdValue';

const ALL_CATEGORY_NAME = "すべて";
const PERPAGE = 20;

/**
 * 該当するカテゴリコードが存在するか（メニュー）の判定
 * 各局UI YTV/CTVの場合は、「culture」->「entertainment」利用
 * @param {Object} models 
 * @param {Object} props 
 * @param {String} categoryCode 
 * @returns 
 */
const isUsableCategory = (models, props, categoryCode) => {
  if (categoryCode === 'all') return true; // カテゴリコード「all」は、許容
  const categoryCodes = _.keys(_.get(models, 'config.data.categoryTags.code2name', {}));
  const isEachCompany = _.get(props.routeHandler, 'isEachCompany');
  const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');

  // 各局UI YTV or CTV 以外、NNNのメニューに含まれているカテゴリに該当するか判定
  if (!_.includes(['ytv', 'ctv'], companyCode) || !isEachCompany) {
    return _.includes(categoryCodes, categoryCode);
  }

  // 各局UI YTV or CTVの場合、カテゴリコード「culture」の代替「entertainment」を許容
  categoryCodes.push('entertainment');
  const specialGenres = categoryCodes.filter(genre => genre !== 'culture')
  return _.includes(specialGenres, categoryCode);
};

/**
 * 画面種別判定・取得
 * -> 最新のニュース一覧(TRENTA UI)[全局] の場合: 'all'
 * -> 最新の各社のニュース一覧(TRENTA UI) の場合: 'trenta'
 * -> 最新の各社のニュース一覧(各社 UI) の場合: ${comapnyCode}
 *     > e.g. ytv, ctv, htv ...
 * @param {Object} props 
 * @returns 
 */
const getPageType = props => {
  const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
  return _.get(props.routeHandler, 'isEachCompany') ?
    companyCode :
    _.get(props.routeHandler, 'isEachTrenta') ?
      'trenta' :
      'all';
}

const convertUiType = (uiType) => uiType === 'all' ? 'trentaUi' : uiType === 'trenta' ? 'eachTrentaUi' : 'eachCompanyUi';

class NewContent extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    topText: PropTypes.string,
  };

  static getPaths = function (models, options, props) {
    const rootPath = this.getRootPath(models, options, props);
    let paths = [];

    const indexes = function (models, options, props) {
      const page = _.get(props, 'routeHandler.query.p');
      let from, to;
      if (page) {
        from = page * PERPAGE - PERPAGE;
        to = page * PERPAGE - 1;
      }
      return {
        from: from || (props && props.fromNum) || 0,
        to: to
          ? to
          : props && props.toNum
          ? props.toNum
          : options && options.numTitles
          ? options.numTitles + (props.fromNum || 0)
          : PERPAGE - 1,
      };
    };

    paths = paths.concat([
      rootPath.concat([
        indexes(models, options, props),
        ['id', 'title', 'thumbnail', 'publish_start_date', 'first_publish_date', 'created_at'],
      ]),
      rootPath.concat(['count']),
      rootPath.concat(['current_page']),
      ['link', _.get(props.routeHandler, 'params.companyCode', 'ntv'), 'nnn_category_menu'],
      ['article', 'getSeo']
    ]);

    return paths;
  };

  static getRootPath = function (models, options, props) {
    const companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    const uiType = getPageType(props);
    const categoryCode = _.get(props, 'categoryCode');
    return ['article', 'new', companyCode, categoryCode, uiType];
  };

  static getPrefetchPaths = function (models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function (models, options, props) {
    return (prefetchResult) => {
      const categoryCode = _.get(props, 'categoryCode');
      // カテゴリ不正チェック (不正な場合, 404画面表示)
      if (!isUsableCategory(models, props, categoryCode)) return { error: ERROR.NOTFOUND };

      const rootPath = this.getRootPath(null, null, props);
      const list = _.get(prefetchResult, ['json'].concat(rootPath));
      if (!list) return { error: ERROR.NOTFOUND };

      this.items = _.compact(
        _.map(_.values(_.omit(list), ['$__path', 'length']), (item) => {
          if (_.isEmpty(_.get(item, 'source_systems.id'))) {
            return;
          }
          return item;
        }),
      );

      // ページクエリ不正チェック
      const totalCount = _.get(list, 'count', null);
      if (validatePageIndexWithinRange(props, totalCount, PERPAGE)) return { error: ERROR.NOTFOUND };

      return null;
    };
  };

  constructor(props, context) {
    super(props, context);
    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.totalCount = this.model.getSync(rootPath.concat('count'));
    this.lastPage = this.totalCount && Math.ceil(this.totalCount / PERPAGE);
    this.companyCode = _.get(props.routeHandler, 'params.companyCode', 'ntv');
    this.items = this.model.getSync(rootPath);
    this.seo = this.model.getSync(['article', 'getSeo']);
    if (this.items && Object.keys(this.items) && Object.keys(this.items).length) {
      const firstIndex = Object.keys(this.items)[0];
      this.currentPage = Math.ceil(firstIndex / PERPAGE) + 1;
    }
    const isEachCompany = _.get(props.routeHandler, 'isEachCompany');
    this.categoryQuery = categoryCode2Name(_.get(props, 'categoryCode', 'all'), _.get(context, 'models.config.data'), this.companyCode) || ALL_CATEGORY_NAME;
    this.categoryList = this.model.getSync(['link', 'ntv', 'nnn_category_menu']); // カテゴリのため（N24 GALLERY）から取得
    // 各局UI (YTV / CTV) の場合、「カルチャー」は「芸能」・「エンタメ」となるため置換
    if (isEachCompany && _.includes(['ytv', 'ctv'], this.companyCode)) {
      this.categoryQuery = categoryCode2Name(_.get(props, 'categoryCode', 'all'), _.get(context, 'models.config.data'), this.companyCode) || ALL_CATEGORY_NAME;
    }

    this.category = _.get(props, 'categoryCode');
    this.setPagerRoute(props);
    this.uiType = getPageType(props);
    this.state = {
      dispose: null,
      loading: false,
      currentPage: 1
    };
  }

  /**
   * PagerBlock のリンク先パラメータの設定
   * @param {Object} props
   */
  setPagerRoute(props) {
    // TRENTA UI（全局） => isEachTrenta: null, isEachCompany: false
    this.to = routes.newTrenta;
    this.params = { categoryCode: this.category };

    // 各社 UI（各社） => isEachCompany: true
    if (_.get(props.routeHandler, 'isEachCompany')) {
      this.to = routes.newEachCompany;
      this.params = { categoryCode: this.category, companyCode: this.companyCode };
    }

    // TRENTA UI（各社） => isEachTrenta: true
    if (_.get(props.routeHandler, 'isEachTrenta')) {
      this.to = routes.newEachTrenta;
      this.params = { categoryCode: this.category, companyCode: this.companyCode };
    }
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  static getDerivedStateFromProps(props, state) {
    if (_.get(props, 'routeHandler.query.p') != state.currentPage) {
      return {
        currentPage: _.get(props, 'routeHandler.query.p', 1),
      };
    }
    return null;
  }

  render() {
    let cards = [];
    const companyCode = _.get(this.props.routeHandler, 'params.companyCode', 'ntv');

    // 特定の閾値より大きいページ数ではnofollowする
    const nofollowThreshold = getNofollowThresholdValue(this.seo, convertUiType(this.uiType), 'new', this.category) || null;

    let items = this.items;
    items = _.omit(items, ['count', 'current_page']);
    if (items && _.size(items)) {
      items = _.slice(_.values(items), 0, PERPAGE);

      // preload imageの設定
      const articleImages = _.compact(
        _.map(
          _.filter(items, e => e && e.source_systems),
          itemData => getCardResizeImage({ itemData, new: true }, this.context),
        ),
      );
      if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);
    }

    const isEachCompany = _.get(this.context.routeHandler, 'isEachCompany');

    cards = _.compact(
      _.map(items || [], (itemData, index) => {
        if (!itemData || !itemData.source_systems) return;
        return (
          <TitleCard
            lineLayout={!this.context.spMode}
            key={`new_card_${itemData.source_systems.id}`}
            itemData={itemData}
            default={this.context.spMode}
          />
        );
      }),
    );

    let parts;
    if (this.context.routeHandler.isEachTrenta || this.context.routeHandler.isEachCompany) {
      // TRENTA UI / 各社 UIで、利用するロゴ画像が異なる
      const imageType = this.context.routeHandler.isEachTrenta ? 'img_src.l' : 'img_src.e_m';
      parts = (
        <>
          <div className="newContent-header">
            <div className="newContent-header-left">
              <div className="newContent-header-img">
                <img src={_.get(getBroadcasterData(companyCode), imageType)} 
                  className={classnames('card-logo', { 'eachCompany' : isEachCompany })}
                  alt={_.get(getBroadcasterData(companyCode), 'label')} 
                />
              </div>
              <h2 className="newContent-header-title">{_.get(getBroadcasterData(companyCode), 'label')}の新着記事</h2>
            </div>
          </div>
          <ScrollMenu categoryTag={true} list={this.categoryList} />
        </>
      );
    } else {
      parts = <ScrollMenu categoryTag={true} list={this.categoryList} />;
    }

    const canonical = `/new/${_.get(this.props, 'categoryCode')}${
      this.currentPage > 1 ? `?p=${this.currentPage}` : ''
    }`;

    const topText = _.get(this.context, 'topText');
    // OGタイトルにカテゴリー名と１ページ目以降の場合はページ数を入れる
    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const pageIndex = this.currentPage > 1 ? `${this.currentPage}ページ目 ` : '' ;

    // meta title 設定
    let titleTag, titleTagCategory;
    if (isEachCompany) {
      titleTag = `${pageIndex}新着のニュース一覧｜${bloadcaster.nnn}｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース`
      titleTagCategory = `${pageIndex}新着のニュース一覧｜${this.categoryQuery}｜${bloadcaster.nnn}｜${bloadcaster.label}のニュース・${bloadcaster.area}のニュース`;
    } else {
      titleTag = company === 'n24'
        ? `${pageIndex}新着のニュース一覧｜${topText}`
        : `${pageIndex}${bloadcaster.label}の新着ニュース一覧｜${topText}`

      titleTagCategory = company === 'n24'
        ? `${pageIndex}新着のニュース一覧｜${this.categoryQuery}｜${topText}`
        : `${pageIndex}${bloadcaster.label}の新着ニュース一覧｜${this.categoryQuery}｜${topText}`
    }

    // meta description 設定
    let description;
    if (this.categoryQuery === ALL_CATEGORY_NAME) {
      if (company === 'n24') {
        description = '日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」の新着ニュースです。政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。';
      } else {
        // 入稿元各局記事 description（カテゴリなし）
        if (!_.get(this.props.routeHandler, 'isEachCompany')) {
          description = `${bloadcaster.label}の新着ニュース一覧です。日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。`;
        } else {
          description = `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」の新着ニュース一覧です。${bloadcaster.area}の最新ニュースや話題のトピックを配信しています。`;
        }
      }
    } else {
      const category = _.find(this.categoryList, { title: this.categoryQuery });
      if (category?.content) {
        description = removeTag(category.content);
      }
      // 入稿元各局記事 description（カテゴリあり）
      if (company !== 'n24') {
        if (!_.get(this.props.routeHandler, 'isEachCompany')) {
          description = `${bloadcaster.label}の${this.categoryQuery}カテゴリの新着ニュース一覧です。日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」は政治、経済、国際、社会、スポーツ、カルチャー・エンタメ・芸能、ライフなど、ニュース速報のほか天気、地震、津波、台風など防災・気象情報を配信しています。`;
        } else {
          description = `${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」の${this.categoryQuery}カテゴリの新着ニュース一覧です。${bloadcaster.area}の${this.categoryQuery}ニュースを配信しています。`;
        }
      }
    }

    // ProfilePage構造化タグ設定
    const ldJsonList = []
    // TRENTA各局UI NTVの新着記事画面のみ構造化タグを追加する
    if (this.uiType === 'trenta' && this.companyCode === 'ntv') {
      const getPageSchemaObjects = new GetPageSchemaObjects({
        item: null,
        canonical: null,
        image: null
      }, this.context);

      const profilePageObj = getPageSchemaObjects.generateProfilePageSchema();
      ldJsonList.push(
        {
          type: 'application/ld+json',
          innerHTML: JSON.stringify(profilePageObj),
        },
      )
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });

            const linkTags = this.props.getPreloadImages();
            let helmetTitle;
            if (this.categoryQuery === ALL_CATEGORY_NAME) {
              helmetTitle = titleTag;
              metas.push({ property: 'og:title', content: shortTitle(titleTag) })
            } else {
              helmetTitle = titleTagCategory;
              const category = _.find(this.categoryList, { title: this.categoryQuery });
              if (category?.content) {
                metas.push({ property: 'og:title', content: shortTitle(titleTagCategory) });
              }
            }
            return (
              <Helmet 
                title={helmetTitle}
                meta={metas}
                link={linkTags}
                script={ldJsonList}
              />
            );
          }}
        </HtmlContext.Consumer>
        <div className="newContent">
          {parts}
          <div className="newContent-body">
            {!_.isEmpty(cards) ? cards : <p>該当のコンテンツはありません</p>}
            {/* Taboola */}
            <div key={`category_taboola_${Date.now()}`}>
              <TaboolaWidget target="below1" />
            </div>
          </div>
          {this.lastPage > 1 && (
            <div className="btn-block">
              <PagerBlock
                currentPage={this.currentPage}
                lastPage={this.lastPage}
                to={this.to}
                params={this.params}
                showBtn={4}
                nofollowThreshold={nofollowThreshold}
              />
            </div>
          )}
        </div>
        <Analytics pageTitle={titleTag} path={canonical} env={_.get(this.context, 'models.config.data.env')} />
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(NewContent);
root.getPaths = NewContent.getPaths;
root.getRootPath = NewContent.getRootPath;
root.getPrefetchPaths = NewContent.getPrefetchPaths;
root.afterPrefetch = NewContent.afterPrefetch;
export default root;
