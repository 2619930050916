import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { Helmet } from 'react-helmet';
import Analytics from '../../../common/components/Analytics';
import HtmlContext from '../../../common/context/HtmlContext';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import ThumbnailList from './ThumbnailList';
import routes from '../../../common/routes';
import MainViewLink from '../../../common/components/MainViewLink';
import { getGenres2Names, getGenres2Code, getArticle2ProviderCode } from '../../../../common/getGenreCategories';
import { NotFoundError } from '../../../common/components/ErrorBoundary';
import CxenseManager from '../../../common/components/CxenseManager';
import { urlSizeFormat } from '../../../../common/preloadImages';
import * as ERROR from '../../../../constants/error';
import url from 'url';
import { GetArticleLinkId } from '../../../../common/getSourceProjectId';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import { MAX_WIDTH_IMAGE } from '../../../../constants/config'
import { validatePageIndexWithinRange } from "../../../common/utils/validateQueryParameters";

class ArticlesContentImages extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    history: PropTypes.object,
    isSpaError: PropTypes.func,
  };

  static getPaths = function(models, options, props) {
    const rootPath = this.getRootPath(models, options, props);

    return [rootPath];
  };

  static getRootPath = function(models, options, props) {
    const articleId = _.get(props, 'id');
    return ['article', 'images', articleId];
  };

  static getPrefetchPaths = function(models, options, props) {
    return this.getPaths(models, options, props);
  };

  static afterPrefetch = function(models, options, props) {
    return (prefetchResult) => {
      const rootPath = this.getRootPath(null, null, props);
      const item = _.get(prefetchResult, ['json'].concat(rootPath));

      if (!item || (_.get(props.routeHandler, 'isEachCompany') && _.get(item.source_systems, 'project') === 'n24')) {
        return { error: ERROR.NOTFOUND };
      }

      // ページクエリ不正チェック
      const totalCount = _.get(item, 'images', []).length;
      if (validatePageIndexWithinRange(props, totalCount, 1)) return { error: ERROR.NOTFOUND };
      return null;
    };
  };

  constructor(props, context) {
    super(props, context);

    const rootPath = this.constructor.getRootPath(context.models, null, props);
    this.model = (props.pathEvaluator || props.model.pathEvaluator).batch(100);
    this.item = this.model.getSync(rootPath);
    this.providerPrefix = _.get(context, 'models.config.data.providerPrefix', {});
    const isEachCompany = _.get(context, 'routeHandler.isEachCompany');
    const providerCode = isEachCompany ? getArticle2ProviderCode(this.item) : 'ntv';
    const categorieTags = getGenres2Names(_.get(this.item, 'genres'), _.get(context, 'models.config.data'), providerCode);
    if (categorieTags) {
      this.item.categoryTags = categorieTags;
      this.item.categoryTag = _.get(this.item, 'categoryTags.0');
    }
    this.articleTo = routes.articleDetail;
    this.articleContentImageUiViews = ['ArticleDetailContentImages', 'ArticleDetailContentImagesEachCompany'];
    let id = GetArticleLinkId(this.item, this.providerPrefix);
    if (!id) {
      id = _.get(this.item, 'article_id');
    }
    this.canonicalArticleId = id;
    this.articleParams = { id };
    this.to = routes.articlesImages;
    this.params = { id };
    this.AD = '';

    if (this.articleContentImageUiViews.includes(_.get(context, 'routeHandler.uiView'))) {
      const companyCode = _.get(context, 'routeHandler.params.companyCode');
      const categoryCode = getGenres2Code(_.get(this.item, 'genres'), _.get(context, 'models.config.data'), providerCode);
      this.articleTo = isEachCompany ? routes.articleDetailEachCompany : routes.articleDetail;
      this.to = isEachCompany ? routes.articleDetailImagesEachCompany : routes.articleDetailImages;

      this.articleParams = { categoryCode, id };
      this.params = { categoryCode, id };
      if (isEachCompany && companyCode) {
        this.articleParams = _.assign(this.articleParams, { companyCode });
        this.params = _.assign(this.params, { companyCode });
      }
      this.AD =_.get(this.articleParams, 'categoryCode') === 'sponsored' ? '【AD】': ''
    }

    this.images = _.get(this.item, 'images');

    // preload imageの設定
    if (this.images?.length) {
      const articleImages = _.compact(
        _.map(
          _.filter(this.images, e => e && e.url),
          e => urlSizeFormat(e.url, MAX_WIDTH_IMAGE),
        ),
      );
      if (!_.isEmpty(articleImages)) this.props.setPreloadImages(articleImages);
    }

    this.state = {};
    // BlueroseLayoutで使用する為、記事データをcontextに格納
    this.context.models.articleData = this.item;
  }

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    const index = _.get(this.props, 'routeHandler.query.p');
    const articlePath = _.get(this.props, 'id');
    // ジャンル（カテゴリ）なし時のエラー対応
    let articleParams = {
      id: articlePath,
      companyCode: _.get(this.props.routeHandler, 'params.companyCode')
    }
    let articleTo = _.get(this.props.routeHandler, 'isEachCompany') ? routes.articlesEachCompany : routes.articles;

    // カテゴリありの場合、カテゴリ付きにルーティング
    if (_.get(this.articleParams, 'categoryCode')) {
      if(articlePath.length > 32 && _.get(this.props.routeHandler, 'isEachCompany')) {
        articleParams = {categoryCode: this.articleParams.categoryCode, id: articlePath, companyCode: _.get(this.props.routeHandler, 'params.companyCode')};
        articleTo = routes.articleDetailEachCompany
      } else {
        articleParams = this.articleParams
        articleTo = this.articleTo;
      }
    }

    if (!this.item) {
      if (_.get(this.context, 'isSpaError')) {
        this.context.isSpaError();
      }
      throw new NotFoundError();
    }

    const mainImg = _.get(this.images, `${index - 1}`);
    let title = '画像詳細';
    let gaTitle = '';
    dayjs.extend(utc);
    dayjs.extend(timezone);
    dayjs.tz.setDefault("Asia/Tokyo");

    if (_.get(this.item, 'first_publish_date')) {
      gaTitle = dayjs(this.item.first_publish_date)
        .tz()
        .format('YYYY-MM-DD HH:mm:ss');
    }
    if (_.get(mainImg, 'caption')) {
      title = mainImg.caption + '｜' + title;
      gaTitle = mainImg.caption + '｜' + gaTitle;
    }
    if (_.get(this.item, 'title')) {
      title = this.item.title + '｜' + title;
      gaTitle = this.item.title + '｜画像詳細' + '｜' + gaTitle;
    }

    let canonical = `/articles/${this.canonicalArticleId}/image`;
    const categoryCode = getGenres2Code(_.get(this.item, 'genres'), _.get(this.context, 'models.config.data'));
    if (categoryCode) {
      canonical = `/category/${categoryCode}/${this.canonicalArticleId}/image`;
    }
    if (typeof window !== 'undefined') {
      const parsedUrl = url.parse(window.location.href, true);
      if (_.get(parsedUrl, 'query.p')) {
        canonical += '?p=' + parsedUrl.query.p;

        // ページタイトルにページ数を入れる(GAtitleは変更しない)
        if (parsedUrl.query.p > 1 && title) {
          title += `｜${parsedUrl.query.p}ページ目`;
        }
      }
    }

    const company = _.get(this.props.routeHandler, 'params.companyCode', 'n24');
    const bloadcaster = getBroadcasterData(company)
    const pageIndex = _.get(this.props.routeHandler, 'query.p', '1') > 1 ? `${_.get(this.props.routeHandler, 'query.p')}ページ目 ` : '' ;

    let titleTag;
    let description;
    if (company === 'n24') {
      if (!_.isEmpty(_.get(mainImg, 'caption'))) {
        titleTag = `${this.AD}${pageIndex}【画像】${(_.get(mainImg, 'caption'))}｜${_.get(this.item, 'title')}｜画像詳細｜日テレNEWS NNN`;
        description = `${_.get(mainImg, 'caption')}｜${_.get(this.item, 'title')}｜の画像詳細。日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」。`;
      } else {
        titleTag = `${this.AD}${pageIndex}【画像】｜${_.get(this.item, 'title')}｜画像詳細｜日テレNEWS NNN`;
        description = `${_.get(this.item, 'title')}｜の画像詳細。日本テレビ系NNN30局のニュースサイト「日テレNEWS NNN」。`;
      }
    } else {
      if (!_.isEmpty(_.get(mainImg, 'caption'))) {
        titleTag = `${this.AD}${pageIndex}【画像】${(_.get(mainImg, 'caption'))}｜${_.get(this.item, 'title')}｜画像詳細｜${bloadcaster.nnn}`;
        description = `${_.get(mainImg, 'caption')}｜${_.get(this.item, 'title')}｜の画像詳細。${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」。`;
      } else {
        titleTag = `${this.AD}${pageIndex}【画像】｜${_.get(this.item, 'title')}｜画像詳細｜${bloadcaster.nnn}`;
        description = `${_.get(this.item, 'title')}｜の画像詳細。${bloadcaster.label}のニュースサイト「${bloadcaster.nnn}」。`;
      }
    }

    return (
      <React.Fragment>
        <HtmlContext.Consumer>
          {({ shortTitle }) => {
            const metas = [];

            metas.push({ property: 'og:title', content: titleTag });
            metas.push({ property: 'og:type', content: 'article' });
            metas.push({ name: 'description', content: description });
            metas.push({ property: 'og:description', content: description });
            metas.push({ name: 'robots', content: 'noindex' });
            const originalBroadCaster = getBroadcasterData(_.get(this.item, 'source_systems.project', 'n24'));
            const providerID = originalBroadCaster.title !== "n24" ? originalBroadCaster.title.toUpperCase() : 'NTV';
            metas.push({ name: 'popIn:category', content: providerID })

            const linkTags = this.props.getPreloadImages();

            return <Helmet title={titleTag} meta={metas} link={linkTags} />;
          }}
        </HtmlContext.Consumer>
        <div className="articleDetail contentImages">
          <h1 className="articleDetail-back-article">
            <MainViewLink
              className="articleDetail-back-article-link"
              to={articleTo}
              params={articleParams}
            >
              <i className="title-arrow fa-article-title-arrow" />
              {_.get(this.item, 'title')}
            </MainViewLink>
          </h1>
          {this.images && this.images.length ? (
            <ThumbnailList
              images={this.images}
              current={index}
              to={this.to}
              params={this.params}
              articleParams={articleParams}
              articleTo={articleTo}
              articleImage
              item={this.item}
            />
          ) : (
            <p>該当コンテンツが存在しません。</p>
          )}
        </div>
        <Analytics
          pageTitle={gaTitle}
          path={canonical}
          categories={_.get(this.item, 'categoryTags')}
          env={_.get(this.context, 'models.config.data.env')}
          abbr={_.get(bloadcaster, 'abbr') || 'NTV'}
        />
        {/* <CxenseManager siteId={_.get(this.context, 'models.config.data.analytics.cxense_config.siteId')} /> */}
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(ArticlesContentImages);
root.getPaths = ArticlesContentImages.getPaths;
root.getRootPath = ArticlesContentImages.getRootPath;
root.getPrefetchPaths = ArticlesContentImages.getPrefetchPaths;
root.afterPrefetch = ArticlesContentImages.afterPrefetch;
export default root;
