import _ from 'lodash';
import dayjs from 'dayjs';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { withLayoutContext } from '../../../common/context/LayoutContext';
import BackgroundImage from '../../../common/components/BackgroundImage';
import nl2br from '../../../../common/react-nl2br';
import Player from './Player';
import getArticleImage from '../../../../common/getArticleImage';
import getImage from '../../../../common/getImage';
import MainViewLink from '../../../common/components/MainViewLink';
import routes from '../../../common/routes';
import url from 'url';
import { getGenres2Name, getGenres2Code, getArticle2ProviderCode } from '../../../../common/getGenreCategories';
import getBroadcasterData from '../../../../common/getBroadcasterData';
import ArticleEmbed from './ArticleEmbed';
import { GetArticleLinkId } from '../../../../common/getSourceProjectId';
import LiteYoutubeEmbedWrapper from '../player/LiteYoutubeEmbedWrapper';
import { MAX_SIZES_IMAGE, MAX_WIDTH_IMAGE } from "../../../../constants/config";
import DummyPlayButton from '../player/DummyPlayButton';
import ArticleDetailHeaderContents from './ArticleDetailHeaderContents';

class PlayerModule extends React.Component {
  static contextTypes = {
    routeHandler: PropTypes.object,
    spMode: PropTypes.bool,
    models: PropTypes.object,
    getModelData: PropTypes.func,
    projectRefIdMapping: PropTypes.object,
  };

  constructor(props, context) {
    super(props, context);

    this.getImageUrl = this.getImageUrl.bind(this);
    this.buildParagraphs = this.buildParagraphs.bind(this);
    this.buildTitle = this.buildTitle.bind(this);
    this.buildPlainText = this.buildPlainText.bind(this);
    this.buildRitchText = this.buildRitchText.bind(this);
    this.buildParagraphImage = this.buildParagraphImage.bind(this);
    this.buildEmbed = this.buildEmbed.bind(this);
    this.isAllowYoutubeVideo = this.isAllowYoutubeVideo.bind(this);
    this.generateVideoPlayer = this.generateVideoPlayer.bind(this);
    this.providerPrefix = _.get(context, 'models.config.data.providerPrefix', {});

    this.projectRefIdMapping = _.get(context, 'projectRefIdMapping');
  }

  componentDidMount() {
    this._isMounted = true;
    const self = this;

  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  // 画像URLを整形
  getImageUrl(imageUrl) {
    if (!imageUrl) return '';

    const urlParse = url.parse(imageUrl, true);
    urlParse.query.w = MAX_WIDTH_IMAGE;
    return url.format(urlParse);
  }


  //タイトル(小見出し)生成
  buildTitle(title, index) {
    return (
      <h2 key={`content_paragraph_${index}_title`} className="player-text">
        {title}
      </h2>
    );
  }

  // プレーンテキスト生成
  buildPlainText(value, title, index) {
    return (
      <div key={`text-paragraph-${index}`}>
        {title}
        <p key={`content_paragraph_${index}`} className="player-text">
          {nl2br(value)}
        </p>
      </div>
    );
  }

  // リッチテキスト
  buildRitchText(value, title, index) {
    return (
      <div key={`rich_text-paragraph-${index}`}>
        {title}
        <div
          key={`content_paragraph_${index}`}
          className="cXenseParse player-text"
          dangerouslySetInnerHTML={{ __html: value }}
        />
      </div>
    );
  }

  // 本文画像生成
  buildParagraphImage(itemData, title, index) {
    const imageUrl = this.getImageUrl(itemData.url);
    const imgList = _.get(this.props, 'item.images', []);
    const idx = _.findIndex(imgList, item => {
      return _.get(item, 'url') === itemData.url;
    });
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false);
    const providerCode = isEachCompany ? getArticle2ProviderCode(itemData) : 'ntv';
    const categoryCode = getGenres2Code(
      _.get(this.props, 'item.genres'),
      _.get(this.context, 'models.config.data'),
      providerCode,
    );

    // 記事ID取得
    const id = GetArticleLinkId(_.get(this.props, 'item'), this.providerPrefix);
    let to = routes.articlesImages;
    let params = { id };

    if (categoryCode) {
      if (!this.props.companyCode) {
        to = routes.articleDetailImages;
        params = { categoryCode, id };
      } else {
        to = routes.articleDetailImagesEachCompany;
        params = { categoryCode, id, companyCode: this.props.companyCode};
      }
    }

    return (
      <div key={`image-paragraph-${index}`}>
        {title}
        <div className="thumbnail-list">
          <MainViewLink
            to={to}
            params={params}
            query={{ p: idx + 1 }}
            rel='nofollow'
          >
            <div className="thumbnail-list-image">
              <div className='relative thumbnail-wrapper'>
                <img
                  src={`${imageUrl}`}
                  alt={`${itemData.caption || ''}`}
                />
                <img className='picture-icon' src='/images/svg/picture.svg' />
              </div>
              {!this.context.routeHandler.listJson && (
                <div className="thumbnail-text">
                  {/* {(_.get(itemData, 'caption') || imgList.length > 1) && (<i className="fa-thumbnail-icon" />)} */}
                  {`${itemData.caption || ''}`}
                </div>
              )}
            </div>
          </MainViewLink>
        </div>
      </div>
    );
  }

  buildParagraphBanner(itemData) {
    return(
      <div className='article-ad-banner'>
        <div className='article-ad-box' >
          <img className='article-ad-img' src={`${(itemData.url)}`} alt={`${itemData.caption || ''}`}/>
          <a target='_blank' className='article-ad-text' href={this.props.item.custom_data.sponsored_link_url}>
            {this.props.item.custom_data.sponsored_link_text}
          </a>
          <a target='_blank' href={this.props.item.custom_data.sponsored_link_url}>
              <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2 18C1.45 18 0.979167 17.8042 0.5875 17.4125C0.195833 17.0208 0 16.55 0 16V2C0 1.45 0.195833 0.979167 0.5875 0.5875C0.979167 0.195833 1.45 0 2 0H9V2H2V16H16V9H18V16C18 16.55 17.8042 17.0208 17.4125 17.4125C17.0208 17.8042 16.55 18 16 18H2ZM6.7 12.7L5.3 11.3L14.6 2H11V0H18V7H16V3.4L6.7 12.7Z" fill="#767F85"/></svg>
            </a>
        </div>
      </div>
    )
  }
  
  // エンベッド
  buildEmbed(value, title, index) {
    return (
      <div className="embed-paragraph" key={`embed-paragraph-${index}`}>
        {title}
        <ArticleEmbed url={value}/>
      </div>
    );
  }

  /**
   * 記事本文の段落生成
   */
  buildParagraphs(paragraphs) {
    if (_.isEmpty(paragraphs)) return null;

    return _.compact(_.map(paragraphs, (itemData, index) => {
      let title = '';

      // タイトルもしくは小見出し
      if (_.get(itemData, 'title') && itemData.title.length) {
        title = this.buildTitle(itemData.title, index);
        if (itemData.type === 'title' && title) {
          return title;
        }
      }

      // プレーンテキスト
      if (itemData.type === 'text' && itemData.value) {
        return this.buildPlainText(_.get(itemData, 'value', ''), title, index);
      }

      // リッチテキスト
      if (itemData.type === 'rich_text' && itemData.value) {
        return this.buildRitchText(itemData.value, title, index);
      }

      // 本文画像
      if (itemData.type === 'image' && _.get(itemData, 'url')) {
        
        // 記事広告の場合、最後の画像はバナーとして表示を行う
        const itemCustomData = _.get(this.props, 'item.custom_data', {});  
        if(itemCustomData?.sponsored_link_url && itemCustomData?.sponsored_link_text && index === paragraphs.length - 1) {
          return this.buildParagraphBanner(itemData)
        }
        return this.buildParagraphImage(itemData, title, index);
      }

      // エンベッド
      if (itemData.type === 'embed' && itemData.value) {
        return this.buildEmbed(itemData.value, title, index);
      }
    }));
  }

  /**
   * Youtube動画を優先的に利用するかどうか
   * 
   * youtubeVideoRestrictCategoriesに自分の記事のカテゴリーが含まれている→false
   * 
   * 記事のカスタムデータにstreaks_flagがtrueである→false
   * 
   * youtubeURLに含まれるメディアIDと記事に登録されているメディアIDが異なる→false
   * 
   * 記事に登録されているメディアIDがない→false
   * 
   * 記事のカスタムデータにyoutubeURLが存在しない→false
   * @return {boolean}
   */
  isAllowYoutubeVideo() {
    const youtubeVideoRestrictCategories = _.get(this.props, 'item.youtubeVideoRestrictCategories', {}); // Youtubeの利用を制限するカテゴリ
    const isRestrictCategory = _.get(youtubeVideoRestrictCategories, _.get(this.props, 'item.categoryCode'), true);

    if (
      isRestrictCategory || // youtubeVideoRestrictCategoriesに自分の記事のカテゴリーが含まれている
      !_.get(this.props, 'item.custom_data.__youtube_url') || // 記事のカスタムデータにyoutubeURLが存在しない
      _.get(this.props, 'item.custom_data.streaks_flag') // 記事のカスタムデータにstreaks_flagがtrueである
    ) {
      return false;
    } else {
      try {
        const parsedUrl = url.parse(_.get(this.props.item, 'custom_data.__youtube_url'), true);
        const mediaIdInQuery = _.get(parsedUrl, 'query.__media');
        const currentMediaId = _.get(this.props, 'item.media.media_id');

        // 記事に登録されているメディアIDがない
        // youtubeURLに含まれるメディアIDと記事に登録されているメディアIDが異なる
        if (!currentMediaId || (mediaIdInQuery !== currentMediaId)) {
          return false;
        }
      } catch (e) {
        console.error('[Youtube利用判定]Youtube URLが取得できませんでした。', e);
        return false;
      }
    }

    return true;
  }

  /**
   * 動画プレイヤーの生成
   * 
   * Youtubeの場合はそのまま表示します。
   * 
   * STREAKSの場合エンドユーザーが再生ボタンをクリックするまではプレイヤーを生成しません。
   */
  generateVideoPlayer(_image) {
    const image = _image;
    if (this.isAllowYoutubeVideo()) {
      // Youtubeを利用する場合
      try {
        const youtubeUrl = url.parse(this.props.item.custom_data.__youtube_url, true);
        const youtubeId = _.get(youtubeUrl, 'query.v');

        if (youtubeId) {
          return (
            <div className="player-block">
              <LiteYoutubeEmbedWrapper
                title={_.get(this.props.item, 'title')}
                id={youtubeId}
              />
            </div>
          );
        }
      } catch (e) {
        console.error('[プレイヤー生成]Youtube URLが取得できませんでした。', e);
      }
    }

    if (_.get(this.props, 'isLoaded') && _.get(this.props, 'item.media.sources.0')) {
      // 動画がある場合かつ再生ボタンが押された後
      // それ以外はSTREAKS
      return (
        <div className="player-block">
          <Player
            type="streaks"
            item={this.props.item.media}
            article={this.props.item}
            image={image}
          />
        </div>
      );
    } else if (_.get(image, 'url')) {
      // サムネイルがあってまだ再生されていないorサムネイルのみ
      return (
        <div
          className={classnames('player-block', { 'handle-play-button': this.props.viewPlayerBtn })}
          onClick={this.props.handlePlayButton}
          style={{ position: 'relative' }}
        >
          <BackgroundImage
            backgroundImageSize={MAX_SIZES_IMAGE}
            disableLazyLoad={true}
            className="artwork articles-thumbnail"
            url={image.url}
            altText={_.get(this.props, 'item.title')}
          />
          <DummyPlayButton viewPlayerBtn={_.get(this.props, 'viewPlayerBtn')} />
        </div>
      );
    }
  }

  render() {
    const browserInfo = this.context.getModelData('browserInfo');
    const providerCode = getArticle2ProviderCode(this.props.item);
    const category = getGenres2Name(_.get(this.props.item, 'genres')[0], _.get(this.context, 'models.config.data'), providerCode);
    const categoryCode = getGenres2Code(_.get(this.props.item, 'genres')[0], _.get(this.context, 'models.config.data'), providerCode);
    const bloadcaster = getBroadcasterData(_.get(this.props, 'item.source_systems.project', 'n24'));
    const project = _.get(this.props, 'item.source_systems.project');
    const projectRefId = _.findKey(this.projectRefIdMapping, refId => refId === project);
    const isEachCompany = _.get(this.context, 'routeHandler.isEachCompany', false);

    const companyLogoLink = {
      path: project === 'n24' ? routes.newEachTrenta : routes.homeEachTrenta,
      params: project === 'n24'
        ? { companyCode: 'ntv',  categoryCode: 'all'} // NTV 新着一覧カテゴリ「すべて」画面へ遷移
        : { companyCode: projectRefId === 'n24' ? 'ntv' : projectRefId }
    }

    const newCategoryRouteParam = {};
    if (categoryCode) {
      newCategoryRouteParam.to = routes.newTrenta;
      // YTVの芸能, CTVのエンタメの場合、カルチャーに移動
      newCategoryRouteParam.params = categoryCode === "entertainment"
        ? {categoryCode: "culture"}
        : {categoryCode: categoryCode};
    }

    // 段落生成
    const paragraphs = this.buildParagraphs(_.get(this.props, 'item.paragraphs', []));
    // 記事の最終バージョン配信日時
    const latestDeliveryVersionDate = _.get(this.props.item, 'latest_delivery_version_date');
    let image = getArticleImage(_.get(this.props, 'item'));
    if (!_.get(image, 'url') && _.get(this.props, 'viewPlayerBtn')) {
      let defaultImage;
      try {
        defaultImage = getImage(
          {
            itemData: _.get(this.props, 'item'),
          },
          this.context,
        );
      } catch (e) {}
      if (defaultImage)
        image = {
          url: defaultImage,
        };
    }

    return (
      <React.Fragment>
        <article className="articleDetail-content">
          <ArticleDetailHeaderContents
            item={this.props.item}
            categoryRouteParam={newCategoryRouteParam}
            categoryCode={categoryCode}
            categoryName={category}
            browserInfo={browserInfo}
            companyLogoLink={companyLogoLink}
            bloadcaster={bloadcaster}
            companyCode={this.props.companyCode}
            isEachCompany={isEachCompany}
          />
          {this.generateVideoPlayer(image)}
          {_.get(image, 'url') && _.get(image, 'caption') && !this.context.routeHandler.listJson && (
            <div className="thumbnail-text thumbnail-text-player">{image.caption}</div>
          )}
          {/* 広告挿入位置 */}
          <div className="player-detail">
            {paragraphs}
          </div>
          {/* 記事の最終更新日を表示(記事広告以外の場合 かつ 記事の最終バージョンの値がある場合) */}
          {categoryCode !== "sponsored" && latestDeliveryVersionDate && (
            <div className="player-detail-last-update-date">
              {dayjs(latestDeliveryVersionDate).format('最終更新日：YYYY年M月D日 H:mm')}
            </div>
          )}
          {categoryCode === "sponsored" ?
          <div className='articleDetail-article-ad-credit'>
            <div>提供：{this.props.item.custom_data?.sponsor}</div>
          </div>
          :
           !_.isEmpty(_.get(this.props, 'relatedArticles')) && (
            <section key={`content_paragraph_related_article`} className="player-text">
              <h2 className='related_articles_title'>関連ニュース</h2>
              <ul>
                {_.map(_.get(this.props, 'relatedArticles'), (article, index) => {
                  if (_.get(article, 'to') && _.get(article, 'params')) {
                    // 関連記事
                    return (<li key={`related_article_${index}`} className="related-article-list">
                      <MainViewLink to={article.to} params={article.params}>
                        {article.title}
                      </MainViewLink>
                    </li>)
                  } else {
                    // 関連リンク
                    return (<li key={`related_article_${index}`} className="related-article-list">
                      <a href={article.url}>
                        {article.title}
                      </a>
                    </li>)
                  }
                })}
              </ul>
            </section>
          )}
        </article>
      </React.Fragment>
    );
  }
}
const root = withLayoutContext(PlayerModule);
export default root;
